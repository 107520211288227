<template>
  <div>
    <NavTitle class="mb16" :title="`${isEdit ? '编辑':'添加'}分栏`"/>
    <el-form class="container" label-width="150px">
      <div class="panel">
        <PanelTitle class="mb24" title="常规项" :divided="true"/>
        <div class="content">
          <el-form-item label="分栏名称：" required>
            <el-input placeholder="请输入分栏名称" v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="职位排序配置：">
            <el-checkbox-group v-model="form.sortTypes">
              <el-checkbox :label="item.type" v-for="(item, index) in sortTypesList" :key="index">{{item.title}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="职位默认排序：" v-if="defaultSortTypesList && defaultSortTypesList.length > 0">
            <!-- <el-checkbox-group v-model="form.defaultSortTypes">
              <el-checkbox :label="item.type" v-for="(item, index) in defaultSortTypesList" :key="index">{{item.title}}</el-checkbox>
            </el-checkbox-group> -->
            <el-radio-group v-model="form.defaultSortType">
              <el-radio :label="item.type" v-for="(item, index) in defaultSortTypesList" :key="index">{{item.title}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="列表类型：" required>
            <el-radio-group class="mt16" v-model="form.listType">
              <el-radio class="mb16 mr16" :label="item.type" v-for="(item, index) in postListTypes" :key="index">{{item.title}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否需要定位：">
            <el-radio-group v-model="form.requireGps">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="列表为空是否填充：">
            <el-radio-group v-model="form.requireFill">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="panel">
        <PanelTitle class="mb24" title="职位筛选配置" :divided="true"/>
        <div class="content">
          <el-form-item label="选择筛选类型：" >
            <el-checkbox-group v-model="form.screenTypes">
              <el-checkbox :label="item.type" v-for="(item, index) in screenTypeList" :key="index">{{item.title}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div v-for="(stype, index) in form.screenTypes" :key="index">
            <el-form-item label="结算方式：" v-if="stype == 3">
              <el-checkbox-group v-model="form.settleTypes">
                <el-checkbox :label="item.type" v-for="(item, index) in settleTypeList" :key="index">{{item.title}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="性别要求：" v-if="stype == 4">
              <el-checkbox-group v-model="form.sex">
                <el-checkbox :label="item.type" v-for="(item, index) in sexTypeList" :key="index">{{item.title}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="工作周期：" v-if="stype == 5">
              <el-checkbox-group v-model="form.workDate">
                <el-checkbox :label="item.type" v-for="(item, index) in workDateTypeList" :key="index">{{item.title}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </div>
      </div>

      <div class="panel">
         <PanelTitle class="mb24" title="列表为空时，推荐的列表类型" :divided="true"/>
         <div class="content">
          <el-form-item label="列表类型：" required>
            <el-radio-group class="mt16" v-model="form.emptyType">
              <el-radio class="mb16 mr16" :label="item.type" v-for="(item, index) in postListTypes" :key="index">{{item.title}}</el-radio>
            </el-radio-group>
          </el-form-item>
         </div>
      </div>

      <!-- 分栏金刚区 -->
      <div class="panel">
        <PanelTitle title="分栏金刚区" :divided="true">
          <span class="theme-text pointer" @click="addNavButton">添加</span>
        </PanelTitle>
        <div class="config-list">
          <el-row :gutter="20" v-if="form.buttons && form.buttons.length > 0">
            <el-col :span="12" class="mb16" v-for="(item, index) in form.buttons" :key="index">
              <div class="config-item">
                <div class="flex items-center">
                  <LoadImage class="img-icon" :src="item.pic"></LoadImage>
                  <div>
                    <div class="title">{{item.text}}</div>
                    <div class="desc">金刚区{{index + 1}}</div>
                  </div>
                </div>
                <div class="operate-row">
                  <div class="btn-operate" @click="deleteNavButton(index)">
                    <img src="@/assets/images/icon-delete.png">
                    <span>删除</span>
                  </div>
                  <div class="btn-operate" @click="editNavButton(index)">
                    <img src="@/assets/images/icon-edit.png">
                    <span>编辑</span>
                  </div>
                  <div class="btn-operate" @click="movePre(index)">
                    <img style="transform: rotate(180deg);" src="@/assets/images/next.png">
                    <span>前移</span>
                  </div>
                  <div class="btn-operate" @click="moveNext(index)">
                    <img src="@/assets/images/next.png">
                    <span>后移</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
        </div>
      </div>
    </el-form>
    <div class="flex justify-center items-center">
      <el-button plain @click="$router.go(-1)">取消</el-button>
      <el-button type="primary" class="ml8" @click="submit">{{isEdit ? '保存':'添加'}}</el-button>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import { resParamsApi, resItemsManageApi, resItemsListApi } from '@/api/resConf.js'
import screenData from '@/types/screen.js'
export default {
  name: 'ColumnEdit',
  components: {
    NavTitle,
    PanelTitle,
    LoadImage
  },
  data() {
    return {
      resType: this.$route.query.resType,
      confId: this.$route.query.confId,
      isEdit: this.$route.query.isEdit == 'true',
      editIndex: this.$route.query.editIndex || 0,
      postListTypes: [],
      sortTypesList: screenData.sortTypes,
      screenTypeList: screenData.screenTypes,
      workDateTypeList: screenData.workDateTypes,
      settleTypeList: screenData.settleTypes,
      sexTypeList: screenData.sexTypes,
      form: {
        title: '',
        listType: 0,
        sortTypes: [],
        defaultSortType: [],
        screenTypes: [],
        settleTypes: [],
        sex: [],
        workDate: [],
        emptyType: 0,
        configList: [],
        buttons: [], // 分栏金刚区
        requireGps: false,
        requireFill: false
      }
    }
  },
  computed: {
    defaultSortTypesList() {
      if(!this.form.sortTypes?.length && !this.sortTypesList?.length) return []
      return this.sortTypesList.filter(item => this.form.sortTypes.includes(item.type))
    }
  },
  created() {
    this.getPostListType()
    this.$eventBus.$on('ColumnNavButtonChange', this.columnNavButtonChange)
  },
  activated() {
    
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.path == '/resource/columnList') {
        const { resType, confId, isEdit, editIndex } = vm.$route.query
        vm.resType = resType
        vm.confId = confId
        vm.isEdit = isEdit
        vm.editIndex = editIndex
        vm.resetForm()
        vm.getConfigList()
      }
    })
  },
  methods: {
    resetForm() {
      this.form = {
        title: '',
        listType: 0,
        sortTypes: [],
        defaultSortType: [],
        screenTypes: [],
        settleTypes: [],
        sex: [],
        workDate: [],
        emptyType: 0,
        configList: [],
        buttons: [], // 分栏金刚区
        requireGps: false,
        requireFill: false
      }
    },
    getPostListType() {
      resParamsApi().then(res => {
        this.postListTypes = res.data.postListTypes || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getConfigList() {
      const formData = {
        confId: this.confId,
        grayConfig: this.$store.state.isResourceGray,
        resType: this.resType
      }
      resItemsListApi(formData).then(res => {
        console.log('原列表', res)
        this.configList = res.data.tabs || []
        if(this.isEdit) this.fillDetail()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    fillDetail() {
      // 数据回显
      const detailInfo = this.configList[this.editIndex]
      this.form.title = detailInfo.title || ''
      this.form.listType = Number(detailInfo.type)
      this.form.emptyType = Number(detailInfo.emptyType)
      this.form.requireGps = detailInfo.requireGps || false
      this.form.requireFill = detailInfo.requireFill || false
      if(detailInfo.sortTypes?.length > 0) {
        detailInfo.sortTypes.forEach(item => {
          this.form.sortTypes.push(Number(item.sortType))
          if(item.selected) this.form.defaultSortType = Number(item.sortType)
        })
      }
      if(detailInfo.postScreenTypes?.length > 0) {
        this.form.screenTypes = detailInfo.postScreenTypes.map(item => item.screenType)
        for (const item of detailInfo.postScreenTypes) {
          if(item.screenType == 3) {
            this.form.settleTypes = item.screenOptions.map(item1 => Number(item1.option))
          }
          if(item.screenType == 4) {
            this.form.sex = item.screenOptions.map(item1 => {
              if(item1.option == -1) return 0 // 兼容ios的不限 -1
              return Number(item1.option)
            })
          }
          if(item.screenType == 5) {
            this.form.workDate = item.screenOptions.map(item1 => Number(item1.option))
          }
        }
      }
      if(detailInfo.buttons?.length > 0) {
        this.form.buttons = detailInfo.buttons
      }

    },
    submit() {
      if(!this.form.title.trim()) return this.$tips({message: '请输入分栏名称',type:'warning'})
      const resJson = {
        title: this.form.title.trim(),
        sortTypes: [],
        postScreenTypes: [],
        type: this.form.listType,
        emptyType: this.form.emptyType,
        buttons: this.form.buttons,
        requireGps: this.form.requireGps,
        requireFill: this.form.requireFill
      }
      // 排序配置
      if(this.form.sortTypes.length > 0) {
        this.form.sortTypes.forEach(type => {
          const info = this.sortTypesList.find(item => item.type == type)
          if(info) {
            resJson.sortTypes.push({
              sortName: info.title,
              sortType: info.type,
              selected: this.form.defaultSortType == info.type
            })
          }
        })
      }
      // 筛选配置
      if(this.form.screenTypes?.length > 0) {
        const list = []
        for (const type of this.form.screenTypes) {
          const info = this.screenTypeList.find(item => item.type == type)
          if(info) {
            // 结算
            if(type == 3 && this.form.settleTypes.length > 0) {
              list.push({
                screenType: type,
                screenTitle: info.title,
                screenOptions: this.form.settleTypes.reduce((arr, t) => {
                  const target = this.settleTypeList.find(item => item.type == t)
                  if(target) {
                    arr.push({
                      option: target.type,
                      title: target.title
                    })
                  }
                  return arr
                }, [])
              })
            }

            // 性别
            else if(type == 4 && this.form.sex.length > 0) {
              list.push({
                screenType: type,
                screenTitle: info.title,
                screenOptions: this.form.sex.reduce((arr, t) => {
                  const target = this.sexTypeList.find(item => item.type == t)
                  if(target) {
                    arr.push({
                      option: target.type,
                      title: target.title
                    })
                  }
                  return arr
                }, [])
              })
            }

            // 工作周期
            else if(type == 5 && this.form.workDate.length > 0) {
              list.push({
                screenType: type,
                screenTitle: info.title,
                screenOptions: this.form.workDate.reduce((arr, t) => {
                  const target = this.workDateTypeList.find(item => item.type == t)
                  if(target) {
                    arr.push({
                      option: target.type,
                      title: target.title
                    })
                  }
                  return arr
                }, [])
              })
            }

            else {
              list.push({
                screenType: type,
                screenTitle: info.title,
                screenOptions: []
              })
            }
          }
        }
        resJson.postScreenTypes = list
      }


      console.log('resJson', resJson)

      if(this.isEdit) {
        this.configList.splice(this.editIndex, 1, resJson)
      } else {
        this.configList.push(resJson)
      }
      const formData = {
        resType: this.resType,
        confId: this.confId,
        grayConfig: this.$store.state.isResourceGray,
        tabs: this.configList
      }
      console.log('formData', formData)
      resItemsManageApi(formData).then(res => {
        this.$tips({message:res.msg, type:'success'})
        this.$router.go(-1)
      }).catch(err => {
        this.$tips({message:err, type:'error'})
      })
    },
    addNavButton() {
      this.$router.push({
        path: '/resource/posterEdit',
        query: {
          resType: this.resType,
          confId: this.confId
        }
      })
    },
    editNavButton(index) {
      sessionStorage.setItem('COLUMN_NAV_BUTTON_INFO', JSON.stringify(this.form.buttons[index]))
      this.$router.push({
        path: '/resource/posterEdit',
        query: {
          resType: this.resType,
          confId: this.confId,
          isEdit: true,
          editIndex: index
        }
      })
    },
    deleteNavButton(index) {
      this.$confirm('确认删除吗？').then(action => {
        if(action == 'confirm') {
          this.form.buttons.splice(index, 1)
        }
      })
    },
    movePre(index) {
      if(index <= 0) return
      const preItem = this.form.buttons[index - 1]
      this.$set(this.form.buttons, index - 1, this.form.buttons[index])
      this.$set(this.form.buttons, index, preItem)
    },
    moveNext(index) {
      if(index == this.form.buttons.length - 1) return
      const nextItem = this.form.buttons[index + 1]
      this.$set(this.form.buttons, index + 1, this.form.buttons[index])
      this.$set(this.form.buttons, index, nextItem)
    },
    columnNavButtonChange(e) {
      console.log('分栏金刚区修改', e)
      if(!e) return
      if(e.isEdit) {
        this.form.buttons.splice(e.editIndex, 1, e.data)
      } else {
        this.form.buttons.push(e.data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 16px;
}
.panel {
  background-color: #fff;
  padding-bottom: 1px;
  margin-bottom: 24px;
  .content {
    width: 800px;
    margin: 0 auto;
  }
}

.config-list {
  padding: 16px;
  .config-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    border-radius: 4px;
    height: 90px;
    padding: 0 24px;
    .img-icon {
      width: 44px;
      height: 44px;
      margin-right: 8px;
      border-radius: 4px;
    }
    .title {
      width: 100px;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .desc {
      color: #99A3A3;
      font-size: 14px;
      margin-top: 8px;
    }
  }
  .operate-row {
    display: flex;
    align-items: center;
    .btn-operate {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
  .btn-add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90px;
    text-align: center;
    font-size: 16px;
    color: var(--theme-color);
    background-color: #f5f5f5;
    img {
      width: 35px;
      height: 35px;
      margin-bottom: 10px;
    }
  }
}

</style>